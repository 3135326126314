import {IonIcon, IonNote, IonText, IonButton, useIonViewWillEnter, useIonToast} from '@ionic/react';
import {ticketOutline} from 'ionicons/icons';
import {useCallback, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {UserGroupByHash} from '../../../../../../shared';
import {privateRoutePaths} from '../../../../routes/routePaths';
import {useAuth} from '../../../auth';
import {Layout} from '../../../layout/layout/Layout';
import {acceptInvitation, fetchGroupByHash} from '../../private.api';

import './Invite.css';

export const Invite: React.FC = () => {
  const {getUser} = useAuth();
  const [present] = useIonToast();
  const {hash} = useParams<{hash: string}>();
  const history = useHistory();
  const [group, setGroup] = useState<UserGroupByHash | undefined>();

  useIonViewWillEnter(() => {
    const fetch = async () => {
      try {
        const group = await fetchGroupByHash(hash);
        if (group?.id) {
          setGroup(group);
        } else {
          present((group as any).message, 3000);
          history.replace(privateRoutePaths.base);
        }
      } catch (e: any) {
        present(e.message, 3000);
        history.replace(privateRoutePaths.base);
      }
    };
    fetch();
  }, [present]);

  const onAccept = useCallback(async () => {
    if (!group) {
      return;
    }
    try {
      const result = await acceptInvitation(hash);
      if (result?.id) {
        getUser();
        history.replace(privateRoutePaths.base);
      }
    } catch (e: any) {
      present(e?.message || 'Произошла ошибка', 3000);
    }
  }, [group, history, hash, present, getUser]);

  return (
    <Layout name="Приглашение" doNotShowBack>
      {group && <div className="invite__container">
        <IonText className="invite__header">
          <IonIcon icon={ticketOutline} size="large" color="primary" />
          <p>Вас пригласили в группу</p>
          <h1 className="invite__subheader">{group.name}</h1>
          <IonNote>После вступления вы сможете участвовать в мероприятиях данной группы</IonNote>
        </IonText>
        <IonButton onClick={onAccept}>
          Вступить
        </IonButton>
      </div>}
    </Layout>
  );
};
