import {
  IonChip,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterLink,
  IonText,
  IonToggle,
} from '@ionic/react';
import {ToggleChangeEventDetail} from '@ionic/core';

import {useLocation} from 'react-router-dom';
import {calendarOutline, calendarSharp, moon, sunny} from 'ionicons/icons';
import {useAuth} from '../../auth';
import {privateRoutePaths, publicRoutePaths} from '../../../routes/routePaths';
import {Logo} from '../../../ui-kit/components';
import {useCallback, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';

import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Мероприятия',
    url: privateRoutePaths.meets,
    iosIcon: calendarOutline,
    mdIcon: calendarSharp,
  },
];

enum CustomTheme {
  dark = 'dark',
  light = 'light',
}

const toggleTheme = (theme: CustomTheme) => {
  const addTheme = theme;
  const removeTheme =
    theme === CustomTheme.light ? CustomTheme.dark : CustomTheme.light;
  document.body.classList.add(addTheme);
  document.body.classList.remove(removeTheme);
};

const deleteTheme = () => {
  document.body.classList.remove(CustomTheme.dark);
  document.body.classList.remove(CustomTheme.light);
};

export const Menu: React.FC = () => {
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([
    'rmm_theme_custom',
    'rmm_theme',
  ]);
  const {isAuth, profile} = useAuth();
  const [isSystemTheme, setIsSystemTheme] = useState(!cookies.rmm_theme_custom);
  const [customTheme, setCustomTheme] = useState<CustomTheme>(
    cookies.rmm_theme || CustomTheme.light
  );

  useEffect(() => {
    if (!isSystemTheme) {
      toggleTheme(customTheme);
    }
  }, []);

  const handleSystemthemeChange = useCallback(
    (e: CustomEvent<ToggleChangeEventDetail>) => {
      e.preventDefault();
      if (e.detail.checked !== isSystemTheme) {
        if (!e.detail.checked) {
          setCookie('rmm_theme_custom', e.detail.checked.toString(), {
            path: '/',
          });
          toggleTheme(customTheme);
        } else {
          removeCookie('rmm_theme_custom', {path: '/'});
          deleteTheme();
        }
        setIsSystemTheme(e.detail.checked);
      }
    },
    [isSystemTheme, customTheme, setIsSystemTheme, setCookie, removeCookie]
  );

  const handleSetTheme = useCallback(
    (theme: CustomTheme) => {
      if (isSystemTheme || theme === customTheme) {
        return;
      }
      toggleTheme(theme);
      setCustomTheme(theme);
      setCookie('rmm_theme', theme, {path: '/'});
    },
    [isSystemTheme, customTheme, setCustomTheme, setCookie]
  );

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            <Logo />
          </IonListHeader>
          {profile && (
            <>
              <IonNote>
                {profile.name}
                <br />
                {profile.email}
                <br />
                {profile.unit?.name}
                {profile.groups && profile.groups.length > 0 && (
                  <div>
                    Мои группы:
                    {profile.groups?.map((g) => (
                      <IonChip key={g.id} disabled>
                        {g.name}
                      </IonChip>
                    ))}
                  </div>
                )}
              </IonNote>
            </>
          )}
          {!isAuth && (
            <IonNote>
              Твой проводник в мир встреч, презентаций и веселья. Для
              продолжения необходимо войти в систему.
            </IonNote>
          )}
          {isAuth &&
            profile?.confirmed &&
            profile.active &&
            appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname.includes(appPage.url) ? 'selected' : ''
                    }
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
        </IonList>

        <IonList id="settings-list">
          <IonListHeader>Настройки</IonListHeader>
          <IonItem lines="none">
            <IonLabel>Системная тема</IonLabel>
            <IonToggle
              checked={isSystemTheme}
              onIonChange={handleSystemthemeChange}
            />
          </IonItem>
          <IonItem lines="none" disabled={isSystemTheme}>
            <IonChip
              color={
                customTheme === CustomTheme.light ? 'primary' : 'secondary'
              }
              onClick={() => handleSetTheme(CustomTheme.light)}
            >
              <IonIcon
                icon={sunny}
                color={
                  customTheme === CustomTheme.light ? 'primary' : 'secondary'
                }
              />
              <IonLabel>Светлая</IonLabel>
            </IonChip>
            <IonChip
              color={customTheme === CustomTheme.dark ? 'primary' : 'secondary'}
              onClick={() => handleSetTheme(CustomTheme.dark)}
            >
              <IonIcon
                icon={moon}
                color={
                  customTheme === CustomTheme.dark ? 'primary' : 'secondary'
                }
              />
              <IonLabel>Темная</IonLabel>
            </IonChip>
          </IonItem>
        </IonList>

        <IonNote className="copyright">
          <div className="copyright__links">
            <IonRouterLink
              color="secondary"
              routerLink={publicRoutePaths.agreement}
            >
              Согласие на обработку персональных данных
            </IonRouterLink>
            <br />
            <IonRouterLink
              color="secondary"
              routerLink={publicRoutePaths.privacy}
            >
              Политика обработки персональных данных
            </IonRouterLink>
          </div>
          2021, <a href="https://redmadrobot.ru">red_mad_robot</a>
        </IonNote>
      </IonContent>
    </IonMenu>
  );
};
