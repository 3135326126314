import {useCallback, useMemo, useRef, useState} from 'react';
import {Meet} from '../../meets.entity';
import {fetchMeet, register} from '../../meets.api';
import {Layout} from '../../../layout/layout/Layout';
import './Meet.css';
import {
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonNote,
  IonRow,
  IonSpinner,
  IonText,
  useIonToast,
  useIonViewWillEnter,
} from '@ionic/react';
import {privateRoutePaths} from '../../../../routes/routePaths';
import {useHistory, useParams} from 'react-router';
import {ticketOutline} from 'ionicons/icons';
import QRCode from 'react-qr-code';
import {toPng} from 'html-to-image';
import {format} from 'date-fns';
import {useAuth} from '../../../auth';

export const MeetView: React.FC = () => {
  const {profile} = useAuth();
  const {id} = useParams<{id: string}>();
  const [meet, setMeet] = useState<Meet>();
  const history = useHistory();
  const ticketRef = useRef<HTMLDivElement>(null);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [present] = useIonToast();
  const dateTime =
    meet?.date && format(new Date(meet.date), 'dd.MM.yyyy, HH.mm');

  useIonViewWillEnter(() => {
    const fetch = async () => {
      const meet = await fetchMeet(id);
      if (meet) {
        setMeet(meet);
      } else {
        history.replace(privateRoutePaths.base);
      }
    };
    fetch();
  }, []);

  // const handleDontGo = useCallback(() => {
  //   console.log('ok');
  // }, []);

  const handleGuestRegisterClick = useCallback(async () => {
    if (!profile?.guest || !meet || meet.registrations.length === 1) {
      return;
    }
    try {
      const registration = await register({
        meetId: meet.id,
        userId: profile.id,
      });
      if (registration?.id) {
        present('Регистрация удалась', 3000);
        setMeet({
          ...meet,
          registrations: [{
            ...registration,
            user: profile,
          }],
        })
      }
    } catch (e) {}
  }, [meet, profile, setMeet, present]);

  const handleTicketClick = useCallback(() => {
    if (!meet || !meet.needTicket || ticketRef.current === null) {
      return;
    }
    setTicketLoading(true);
    toPng(ticketRef.current, {cacheBust: true, pixelRatio: 1, quality: 1})
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${meet.name} - билет.png`;
        link.href = dataUrl;
        link.click();
        setTicketLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTicketLoading(false);
      });
  }, [meet, ticketRef, setTicketLoading]);

  const registrationsBlock = useMemo(() => {
    if (!meet || !meet.needRegistration) {
      return null;
    }

    if (!meet.registrations || meet.registrations.length === 0) {
      const meetDate = new Date(meet.date).getTime();

      if (meetDate <= Date.now()) {
        return null;
      }

      return profile?.guest ? (
        <IonButton
          expand="block"
          onClick={handleGuestRegisterClick}
        >
          Регистрация
        </IonButton>
      ) : (
        <IonButton
          expand="block"
          routerLink={`${privateRoutePaths.meets}/${meet.id}${privateRoutePaths.registration}`}
        >
          Регистрация
        </IonButton>
      );
    }

    if (!meet.needTicket) {
      return (
        <IonChip color="success">
          <IonLabel>Регистрация успешна</IonLabel>
          <IonIcon icon={ticketOutline} />
        </IonChip>
      );
    }

    return null;
  }, [meet, profile, handleGuestRegisterClick]);

  const ticketBlock = useMemo(() => {
    if (!meet || !meet.needTicket) {
      return null;
    }
    if (
      meet.needRegistration &&
      (!meet.registrations || meet.registrations.length === 0)
    ) {
      return null;
    }

    let ticket;

    if (!meet.needRegistration) {
      ticket = (
        <QRCode
          value={JSON.stringify({
            meetId: meet.id,
          })}
          size={200}
        />
      );
    } else {
      ticket = (
        <QRCode
          size={200}
          value={JSON.stringify({
            registrationId: meet.registrations[0].id,
          })}
        />
      );
    }

    // const dontGoBlock = () => {
    //   if (!meet || !meet.needRegistration || meet.registrations.length === 0 || meet.registrations[0].confirmed) {
    //     return null;
    //   }
    //   return (
    //     <IonButton
    //       expand="block"
    //       color="medium"
    //       onClick={handleDontGo}
    //     >
    //       Не смогу прийти
    //     </IonButton>
    //   );
    // };

    return (
      <div className="ticket-wrapper">
        <IonChip onClick={handleTicketClick} disabled={ticketLoading}>
          <IonLabel>Сохрани билет, чтобы не потерять</IonLabel>
          {!ticketLoading && <IonIcon icon={ticketOutline} color="primary" />}
          {ticketLoading && <IonSpinner name="lines" color="primary" />}
        </IonChip>
        <IonCard className="ticket">
          <div ref={ticketRef} className="ticket-inner-wrapper">
            <div className="ticket-content-wrapper">
              {`---------- START TICKET ----------`}
              <div className="ticket-header">{meet?.name}</div>
              <div className="ticket-info">
                ДАТАВРЕМЯ: {dateTime};{' '}
                {meet?.place ? `МЕСТО: ${meet?.place};` : ''}{' '}
                {meet?.needRegistration
                  ? `РОБОТ: ${meet?.registrations[0].user?.name}`
                  : ''}
              </div>
              {`-- NO HUMANS -- ONLY ROBOTS --`}
            </div>
            <div className="ticket-qrcode-wrapper">{ticket}</div>
            <div className="ticket-content-wrapper">
              {`----------- END TICKET -----------`}
            </div>
          </div>
        </IonCard>
      </div>
    );
  }, [meet, handleTicketClick, ticketLoading, dateTime]);

  const votingBlock = useMemo(() => {
    if (!meet || !meet.needVote || !meet.voting?.state) {
      return null;
    }

    const fill = meet.voting?.state === 'STARTED' ? 'solid' : 'outline';
    const text =
      meet.voting?.state === 'STARTED'
        ? 'Идет голосование'
        : 'Перейти к голосованию';

    return (
      <div className="voting-info">
        <IonButton
          expand="block"
          color="primary"
          fill={fill}
          routerLink={`${privateRoutePaths.meets}/${meet.id}${privateRoutePaths.voting}`}
        >
          {text}
        </IonButton>
      </div>
    );
  }, [meet]);

  return (
    <Layout name={meet?.name}>
      <div className="meet-container">
        {meet && (
          <>
            <IonImg
              alt=""
              className="image-header"
              src={meet.imageLink || 'assets/images/meet.jpg'}
            />
            <IonGrid>
              <IonRow>
                <IonCol size-xs="12" size-lg="7">
                  <div className="chips">
                    {meet.needRegistration && (
                      <IonChip disabled>Регистрация</IonChip>
                    )}
                    {meet.needTicket && <IonChip disabled>По билетам</IonChip>}
                    {meet.needVote && <IonChip disabled>Голосование</IonChip>}
                  </div>
                  {votingBlock}
                  <div className="info">
                    <IonNote className="meet-datetime" color="primary">
                      {dateTime}
                    </IonNote>
                    {meet.description && (
                      <div>
                        <p>{meet.description}</p>
                      </div>
                    )}
                  </div>

                  <IonText className="info">
                    {meet.place && (
                      <p>
                        <IonNote>Место</IonNote>
                        <br />
                        {meet.place}
                      </p>
                    )}
                    {meet.link && (
                      <p>
                        <IonNote>Ссылка на мероприятие</IonNote>
                        <br />
                        <a href={meet.link}>{meet.link}</a>
                      </p>
                    )}
                  </IonText>
                </IonCol>
                <IonCol size-xs="12" size-lg="5">
                  <div className="meet-interactive">
                    {registrationsBlock}
                    {ticketBlock}
                    {/* {dontGoBlock()} */}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        )}
      </div>
    </Layout>
  );
};
