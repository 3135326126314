import {Meet} from './meets.entity';
import {api} from '../../services/api';
import {Registration, RegistrationCreate, UserUnit, VoteCreate, VotePayload, Voting} from '../../../../shared';

const URL = '/api/meets';

export const fetchMeets = async (): Promise<Meet[] | undefined> => {
  try {
    const meets = await api.get<Meet[]>(URL);
    return meets;
  } catch (e) {
    return undefined;
  }
};

export const fetchUnits = async (): Promise<UserUnit[] | undefined> => {
  try {
    const units = await api.get<UserUnit[]>('/api/units');
    return units;
  } catch (e) {
    return undefined;
  }
};

export const fetchMeet = async (id: string): Promise<Meet | undefined> => {
  try {
    const meet = await api.get<Meet>(`${URL}/${id}`);
    return meet;
  } catch (e) {
    return undefined;
  }
};


export const fetchVotingByMeetId = async (id: string): Promise<Voting | undefined> => {
  try {
    const voting = await api.get<Voting>(`api/votings/${id}`);
    return voting;
  } catch (e) {
    throw e;
  }
};

export const fetchVoteByVotingId = async (id: string): Promise<VotePayload | undefined> => {
  try {
    const vote = await api.get<VotePayload>(`api/votes/${id}`);
    return vote;
  } catch (e) {
    throw e;
  }
};

export const createVote = async (voteCreate: VoteCreate): Promise<VotePayload | undefined> => {
  try {
    const vote = await api.post<VotePayload>(`api/votes`, voteCreate);
    return vote;
  } catch (e) {
    return undefined;
  }
};

export const register = async (data: RegistrationCreate): Promise<Registration | undefined> => {
  try {
    const registration = await api.post<Registration>(`/api/registrations`, data);
    return registration;
  } catch (e: any) {
    throw new Error(e);
  }
};
