import {Voting} from '../../meets.entity';
import './VoteResults.css';
import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonIcon,
  IonNote,
  IonText,
} from '@ionic/react';
import {trophyOutline, warningOutline} from 'ionicons/icons';

const customRound = (num: number, precision = 3) => {
  var power = Math.pow(10, precision);
  return (Math.floor(num * power) / power).toFixed(precision);
};


export const VoteResults: React.FC<{voting: Voting}> = ({voting}) => {
  if (!voting.results?.isValid) {
    return (
      <div className="voting-block results">
        <IonCardHeader>
          <IonCardTitle>Голосование скомпрометировано</IonCardTitle>
          <IonCardSubtitle color="primary">
            Мероприятие: {voting.meet.name}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <div key="warning">
            <IonIcon
              slot="start"
              color="primary"
              size="large"
              icon={warningOutline}
            />
          </div>
          <IonNote>
            В системе произошел сбой (или хакер среди нас).
            <br />
            Придется подождать, пока администраторы разберутся с ситуацией.{' '}
            <br />
            Stay tuned.
          </IonNote>
        </IonCardContent>
      </div>
    );
  }
  if (voting.results) {
    return (
      <div className="voting-block results">
        <IonCardHeader>
          <IonCardTitle>Голосование окончено </IonCardTitle>
          <IonCardSubtitle color="primary">
            Мероприятие: {voting.meet.name}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <div key="winner" className="winner">
            <IonIcon
              slot="start"
              color="primary"
              size="large"
              icon={trophyOutline}
            />
            <IonText color="primary">
              <h2>Победитель</h2>
              <h1>{voting.results.result?.total[0]?.project.name}</h1>
            </IonText>
            <IonNote>
              Итоговый балл: {customRound(voting.results.result?.total[0].score)}
            </IonNote>
          </div>
          <IonList className="projects-list">
            <IonListHeader lines="full">
              <IonLabel>Чуть-чуть не хватило:</IonLabel>
            </IonListHeader>
            {voting.results.result?.total
              ?.slice(1, voting.results.result?.total.length)
              .map((p, i) => (
                <IonItem key={p.project.id}>
                  <IonIcon
                    slot="start"
                    color="secondary"
                    size="small"
                    icon={trophyOutline}
                  />
                  <IonLabel>
                    {i + 2}. {p.project.name}
                    <br />
                    <p>Итоговый балл: {customRound(p.score)}</p>
                  </IonLabel>
                </IonItem>
              ))}
          </IonList>
          <p className="to-be-continued">До новых встреч!</p>
        </IonCardContent>
      </div>
    );
  }

  return null;
};
