import {useCallback, useContext, useState} from 'react';
import {Meet} from '../../meets.entity';
import {fetchMeet, fetchUnits, register} from '../../meets.api';
import {Layout} from '../../../layout/layout/Layout';
import './Registration.css';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonNote,
  IonRow,
  IonSelect,
  IonSelectOption,
  NavContext,
  useIonToast,
  useIonViewWillEnter,
} from '@ionic/react';
import {useParams} from 'react-router';
import {privateRoutePaths} from '../../../../routes/routePaths';
import {changeUnit, useAuth} from '../../../auth';
import {UserUnit} from '../../../../../../shared';
import { format } from 'date-fns';

export const Registration: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const {navigate} = useContext(NavContext);
  const {profile, getUser} = useAuth();
  const [meet, setMeet] = useState<Meet>();
  const [units, setUnits] = useState<UserUnit[]>([]);
  const [unit, setUnit] = useState<string | undefined>(profile?.unitId);
  const [loading, setLoading] = useState(false);
  const [present] = useIonToast();
  const dateTime = meet?.date && format(new Date(meet.date), 'dd.MM.yyyy, HH.mm');

  useIonViewWillEnter(() => {
    const fetch = async () => {
      const [meet, units] = await Promise.all([fetchMeet(id), fetchUnits()]);
      if (meet) {
        if (
          !meet.needRegistration ||
          (meet.registrations && meet.registrations.length > 0)
        ) {
          navigate(`${privateRoutePaths.meets}/${meet.id}`, 'back');
        }
        setMeet(meet);
        if (units && units.length !== undefined) {
          setUnits(units);
        }
      } else {
        navigate(privateRoutePaths.base, 'back');
      }
    };
    fetch();
  }, [navigate]);

  const handleRegister = useCallback(async () => {
    try {
      if (profile && unit && meet) {
        setLoading(true);
        if (profile.unitId !== unit) {
          await changeUnit(unit);
          await getUser();
        }
        const registration = await register({
          meetId: meet.id,
          userId: profile.id,
        });
        if (registration?.id) {
          navigate(`${privateRoutePaths.meets}/${meet.id}`, 'back');
        }
        present('Регистрация удалась', 3000);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      present('Что-то пошло не так, попробуй еще раз', 3000);
    }
  }, [profile, unit, meet, setLoading, navigate, getUser, present]);
  return (
    <Layout name="Регистрация">
      <div className="registration-container">
        {meet && (
          <>
            <IonGrid>
              <IonRow>
                <IonCol size-xs="12" size-lg="8">
                  <div className="info">
                    <IonNote>
                      <div className="registration-datetime">
                        {dateTime}
                      </div>
                    </IonNote>
                    <div>
                      <h3>{meet.name}</h3>
                    </div>
                    <p>
                      <IonNote>
                        Перед тем, как зарегистрироваться на мероприятие,
                        проверь, правильно ли мы определили твой юнит.
                      </IonNote>
                    </p>
                  </div>
                </IonCol>
                <IonCol size-xs="12" size-lg="4">
                  <div className="register-form">
                    <IonItem>
                      <IonSelect
                        value={unit}
                        placeholder="Выбери юнит"
                        onIonChange={(e) => setUnit(e.detail.value)}
                        title="Твой юнит"
                      >
                        {units.map((u) => (
                          <IonSelectOption value={u.id} key={u.id}>
                            {u.name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                    <IonButton
                      expand="block"
                      disabled={!unit || loading}
                      onClick={handleRegister}
                    >
                      Зарегистрироваться
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        )}
      </div>
    </Layout>
  );
};
