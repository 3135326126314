import {useContext} from 'react';
import {EventsContext, EventsContextValues} from '../../components/EventsContext';

export const useEvents = (): EventsContextValues => {
  const eventsContext = useContext(EventsContext);
  if (!eventsContext) {
    throw new Error('useEvents must be used within a EventsProvider.');
  }
  return eventsContext;
};
