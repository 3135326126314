import React from 'react';
import {VotingEvents, VotingEventPayload} from '../../events.entity';

export type EventsContextValues = {
  event?: {
    event: VotingEvents;
    data: VotingEventPayload;
  }
};

export const EventsContext = React.createContext<EventsContextValues | null>(null);
