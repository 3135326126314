import {useCallback, useEffect, useState} from 'react';
import {RawVote, Voting} from '../../meets.entity';
import './Voter.css';
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonReorderGroup,
  IonItem,
  IonLabel,
  IonReorder,
  IonList,
  IonListHeader,
  IonIcon,
  IonText,
  IonSpinner,
} from '@ionic/react';
import {ItemReorderEventDetail} from '@ionic/core';
import {
  flagOutline,
  timeOutline,
  trophyOutline,
} from 'ionicons/icons';

const shuffle = (a: Voting['projects']) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};
const arrayMove = (
  arr: Voting['projects'],
  old_index: number,
  new_index: number
) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      (arr as Array<any>).push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export const Voter: React.FC<{voting: Voting; onVote(vote: RawVote): void, sending?: boolean}> = ({
  voting,
  sending,
  onVote,
}) => {
  const [step, setStep] = useState(-1);
  const [vote, setVote] = useState<RawVote>();
  const meet = voting.meet;
  const currentStep = vote && vote[step];

  const next = useCallback(() => setStep(step + 1), [setStep, step]);
  const prev = useCallback(() => setStep(step - 1), [setStep, step]);

  useEffect(
    () =>
      setVote(
        voting.types.map((t) => ({
          type: t,
          projects: shuffle([...voting.projects]),
        }))
      ),
    [voting, setVote]
  );

  const handleReorder = useCallback(
    (event: CustomEvent<ItemReorderEventDetail>) => {
      const projects = vote && vote[step]?.projects;

      if (vote && projects) {
        arrayMove(projects, event.detail.from, event.detail.to);
        setVote([...vote]);
      }

      event.detail.complete();
    },
    [vote, step, setVote]
  );

  const handleVote = useCallback(() => {
    if (!!vote) {
      onVote(vote);
    }
  }, [vote, onVote]);

  const stepper = (
    <div className="voting-stepper">
      <IonIcon color="primary" size="small" icon={timeOutline} />
      {voting?.types?.map((_, i) => (
        <IonText key={i} color={step >= i ? 'primary' : 'medium'}>
          {' '}
          - {i + 1}
        </IonText>
      ))}
      <IonText color={step === voting?.types?.length ? 'primary' : 'medium'}>
        {' '}
        -
        {' '}
      </IonText>
      <IonIcon
        color={step === voting?.types?.length ? 'primary' : 'medium'}
        size="small"
        icon={flagOutline}
      />
    </div>
  );

  if (step === -1) {
    return (
      <div className="voting-block">
        <IonCardHeader>
          {stepper}
          <IonCardTitle>Гонка началась!</IonCardTitle>
          <IonCardSubtitle color="primary">
            Мероприятие: {meet.name}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <p className="voting-instruction">
            Теперь ты можешь проголосовать.
            <br />
            Помни, что ты голосуешь по нескольким параметрам,
            <br /> которые учитываются с разными долями в результате.
          </p>
          <IonButton
            key="voting-go"
            color="primary"
            expand="block"
            onClick={next}
          >
            Поехали
          </IonButton>
        </IonCardContent>
      </div>
    );
  }

  if (currentStep) {
    return (
      <div className="voting-block">
        <IonCardHeader>
          {stepper}
          <IonCardTitle>{currentStep.type.name}</IonCardTitle>
          <IonCardSubtitle color="primary">
            Доля {currentStep.type.percentage}%
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <p className="voting-instruction">
            Проекты перемешаны рандомно. <br />
            Расставь их в порядке убывания от лучшего к худшему.
          </p>
          <IonReorderGroup
            disabled={false}
            className="projects-list"
            onIonItemReorder={handleReorder}
          >
            {currentStep.projects?.map((p, i) => (
              <IonItem
                key={p.id}
                lines={i === voting.projects.length - 1 ? 'none' : 'full'}
              >
                <IonLabel>
                  {i + 1}. {p.name}
                </IonLabel>
                <IonReorder slot="end" />
              </IonItem>
            ))}
          </IonReorderGroup>
          <div className="voting-buttons">
            <IonButton color="primary" expand="block" onClick={next}>
              Дальше
            </IonButton>
            <IonButton
              color="primary"
              expand="block"
              fill="outline"
              onClick={prev}
            >
              Назад
            </IonButton>
          </div>
        </IonCardContent>
      </div>
    );
  }

  if (!currentStep && vote) {
    return (
      <div className="voting-block">
        <IonCardHeader>
          {stepper}
          <IonCardTitle>Последний шаг</IonCardTitle>
          <IonCardSubtitle color="primary">
            Мероприятие: {meet.name}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <p className="voting-instruction">
            Проверь свой голос перед отправкой
          </p>
          {vote.map((t) => (
            <IonList key={t.type.id} className="projects-list">
              <IonListHeader lines="full" color="medium">
                <IonLabel>{t.type.name}</IonLabel>
              </IonListHeader>
              {t.projects?.map((p, i) => (
                <IonItem
                  key={p.id}
                  lines={i === voting.projects.length - 1 ? 'none' : 'inset'}
                >
                  {i === 0 && (
                    <IonIcon
                      color="primary"
                      size="small"
                      icon={trophyOutline}
                      className="first-of-projects"
                    />
                  )}
                  <IonLabel>
                    {i === 0 ? '' : `${i + 1}. `}
                    {p.name}
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          ))}
          {!sending && <div className="voting-buttons">
            <IonButton color="primary" expand="block" onClick={handleVote}>
              Отправить
            </IonButton>
            <IonButton
              color="primary"
              expand="block"
              fill="outline"
              onClick={prev}
            >
              Назад
            </IonButton>
          </div>}
          {sending && <IonSpinner color="primary"/>}
        </IonCardContent>
      </div>
    );
  }

  return null;
};
