import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import {useLocation} from 'react-router';
import {publicRoutePaths} from '../../../routes/routePaths';
import './Layout.css';

export const Layout: React.FC<{
  name?: string;
  headerContent?: React.ReactNode;
  doNotShowBack?: boolean;
}> = ({name, children, headerContent, doNotShowBack}) => {
  const location = useLocation();
  const isDeep =
    !doNotShowBack &&
    (location.pathname.split('/').length > 2 ||
      location.pathname ===
        publicRoutePaths.privacy ||
      location.pathname ===
        publicRoutePaths.agreement);
  // TODO: notifications
  // const notification = 'Началось голосование';
  // const notificationButtons: Array<ToastButton> = [{
  //   text: 'Посмотреть',
  //   side: 'end',
  //   handler: () => {
  //     console.log('Cancel clicked');
  //   }
  // }];

  return (
    <IonPage id={name}>
      <IonHeader color="primary">
        <IonToolbar>
          <IonButtons slot="secondary">
            {isDeep && <IonBackButton text="Назад" className="display" />}
            {!isDeep && <IonMenuButton />}
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {children}
        {/* <IonToast
          isOpen={true}
          message={notification}
          duration={10000}
          buttons={notificationButtons}
        /> */}
      </IonContent>
    </IonPage>
  );
};
