export const publicRoutePaths = {
  notFound: '*',
  agreement: '/agreement',
  privacy: '/privacy',
};

export const privateRoutePaths = {
  base: '/',
  meets: '/meets',
  registration: '/registration',
  voting: '/voting',
  invite: '/invite',
};
