import {IonContent, IonSpinner} from '@ionic/react';
import {Route, RouteProps, useLocation} from 'react-router';
import {useAuth} from '../../modules/auth';
import {Deactivated, WaitingForConfirmation} from '../../modules/private';
import {Login} from '../../modules/public';
import {privateRoutePaths} from '../routePaths';

type Props = {
  adminRoute?: boolean;
};

export const AppRoute = (props: RouteProps & Props): JSX.Element | null => {
  const {isAuth, profile, loading} = useAuth();
  const location = useLocation();
  const {children, component: Component, render, ...restProps} = props;

  return (
    <Route
      {...restProps}
      render={(renderProps) => {
        if (loading) {
          return (
            <IonContent>
              <IonSpinner
                color="primary"
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                }}
              />
            </IonContent>
          );
        }
        if (!isAuth || !profile) {
          return <Login />;
        } else {
          if (!profile.active) {
            return <Deactivated />;
          }
          if (
            profile?.guest &&
            !profile.confirmed &&
            !location.pathname.includes(privateRoutePaths.invite)
          ) {
            return <WaitingForConfirmation />;
          }
        }

        if (Component) {
          return <Component {...renderProps} />;
        }

        if (render) {
          return render(renderProps);
        }

        return children;
      }}
    />
  );
};
