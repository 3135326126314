import {AppRoute} from './helpers/AppRoute';
import {privateRoutePaths} from './routePaths';
import {Meets, MeetView, Registration, VotingView} from '../modules/meets';
import {Invite} from '../modules/private';
import {Redirect, Route} from 'react-router';

export const privateRoutes = [
  <Route key="base" path={privateRoutePaths.base} component={Meets} exact>
    <Redirect to={privateRoutePaths.meets} />
  </Route>,
  <AppRoute key="meets" path={privateRoutePaths.meets} component={Meets} exact/>,
  <AppRoute key="meet" path={`${privateRoutePaths.meets}/:id`} component={MeetView} exact/>,
  <AppRoute key="voting" path={`${privateRoutePaths.meets}/:id${privateRoutePaths.voting}`} component={VotingView} exact/>,
  <AppRoute key="registration" path={`${privateRoutePaths.meets}/:id${privateRoutePaths.registration}`} component={Registration}/>,
  <AppRoute key="invite" path={`${privateRoutePaths.invite}/:hash`} component={Invite} exact/>,
];
