import React from 'react';
import {Profile} from '../../auth.entity';

export type AuthContextValues = {
  isAuth: boolean;
  profile?: Profile;
  loading: boolean;
  getUser(gaToken?: string, inviteToken?: string): Promise<Profile | undefined>;
};

export const AuthContext = React.createContext<AuthContextValues | null>(null);
