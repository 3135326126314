import {useCallback, useState} from 'react';
import {Meet} from '../../meets.entity';
import {fetchMeets} from '../../meets.api';
import {Layout} from '../../../layout/layout/Layout';
import './Meets.css';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonNote,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import {privateRoutePaths} from '../../../../routes/routePaths';
import {format} from 'date-fns';
import {RefresherEventDetail} from '@ionic/core';
import {ru} from 'date-fns/locale';
import {calendarOutline} from 'ionicons/icons';

type TabValue = 'future' | 'past';
type MeetsWithTitle = {
  title: string;
  today?: boolean;
  month: number;
  year: number;
  meets: Meet[];
};

const formatMeets = (meets: Meet[], tab: TabValue): MeetsWithTitle[] => {
  const displayMeetsWithTitle: Array<MeetsWithTitle> = [];

  const timestamp = new Date();
  const currentDay = timestamp.getDate();
  const currentMonth = timestamp.getMonth() + 1;
  const currentYear = timestamp.getFullYear();

  meets
    .filter((m) => {
      const date = new Date(m.date);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const isTodayOrFuture =
        (year === currentYear && month === currentMonth && day >= currentDay) ||
        (month > currentMonth && year === currentYear) ||
        year > currentYear;
      return tab === 'future' ? isTodayOrFuture : !isTodayOrFuture;
    })
    .sort((a, b) =>
      tab === 'future'
        ? new Date(a.date).getTime() - new Date(b.date).getTime()
        : new Date(b.date).getTime() - new Date(a.date).getTime()
    )
    .forEach((m) => {
      const date = new Date(m.date);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const monthTitle = format(date, 'LLLL yyyy', {locale: ru});
      if (
        day === currentDay &&
        month === currentMonth &&
        year === currentYear
      ) {
        const todayMeetsWithTitle = displayMeetsWithTitle.find(
          (dm) => dm.today
        );
        if (todayMeetsWithTitle) {
          todayMeetsWithTitle.meets.push(m);
        } else {
          displayMeetsWithTitle.push({
            title: 'Сегодня',
            today: true,
            month: month,
            year: year,
            meets: [m],
          });
        }
      } else if (month === currentMonth && year === currentYear) {
        const thisMonthMeetsWithTitle = displayMeetsWithTitle.find(
          (dm) => dm.month === currentMonth && !dm.today
        );
        if (thisMonthMeetsWithTitle) {
          thisMonthMeetsWithTitle.meets.push(m);
        } else {
          displayMeetsWithTitle.push({
            title:
              tab === 'future'
                ? 'В этом месяце'
                : monthTitle[0].toLocaleUpperCase() + monthTitle.slice(1),
            month: month,
            year: year,
            meets: [m],
          });
        }
      } else {
        const monthMeetsWithTitle = displayMeetsWithTitle.find(
          (dm) => dm.month === month && dm.year === year
        );
        if (monthMeetsWithTitle) {
          monthMeetsWithTitle.meets.push(m);
        } else {
          displayMeetsWithTitle.push({
            title: monthTitle[0].toLocaleUpperCase() + monthTitle.slice(1),
            month: month,
            year: year,
            meets: [m],
          });
        }
      }
    });

  return displayMeetsWithTitle;
};

export const Meets: React.FC = () => {
  const [meetsFuture, setMeetsFuture] = useState<MeetsWithTitle[]>([]);
  const [meetsPast, setMeetsPast] = useState<MeetsWithTitle[]>([]);
  const [tab, setTab] = useState<TabValue>('future');
  useIonViewWillEnter(() => {
    const fetch = async () => {
      try {
        const meets = await fetchMeets();
        if (meets && meets.length !== undefined) {
          setMeetsPast(meets.length === 0 ? [] : formatMeets(meets, 'past'));
          setMeetsFuture(
            meets.length === 0 ? [] : formatMeets(meets, 'future')
          );
        } else {
          setMeetsPast([]);
          setMeetsFuture([]);
        }
      } catch (e) {}
    };
    fetch();
  }, []);

  const refreshMeets = useCallback(
    async (event: CustomEvent<RefresherEventDetail>) => {
      try {
        const meets = await fetchMeets();
        if (meets && meets.length !== undefined) {
          setMeetsPast(meets.length === 0 ? [] : formatMeets(meets, 'past'));
          setMeetsFuture(
            meets.length === 0 ? [] : formatMeets(meets, 'future')
          );
        } else {
          setMeetsPast([]);
          setMeetsFuture([]);
        }
      } catch (e) {}
      event.detail.complete();
    },
    []
  );

  const displayMeetsWithTitle: Array<MeetsWithTitle> =
    tab === 'future' ? meetsFuture : meetsPast;

  return (
    <Layout name="Мероприятия">
      <IonRefresher slot="fixed" onIonRefresh={refreshMeets}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <div className="tabs">
        <IonSegment value={tab}>
          <IonSegmentButton value="future" onClick={() => setTab('future')}>
            Активные
          </IonSegmentButton>
          <IonSegmentButton value="past" onClick={() => setTab('past')}>
            Прошлые
          </IonSegmentButton>
        </IonSegment>
      </div>
      <div className="meets">
        {!displayMeetsWithTitle || displayMeetsWithTitle.length < 1 ? (
          <IonNote className="zeroscreen">Ничего не найдено</IonNote>
        ) : (
          <div className="meets-timeline"></div>
        )}
        {displayMeetsWithTitle.map((dm, i) => (
          <IonGrid key={i}>
            <IonRow>
              <IonCol>
                <IonText color={dm.today ? 'primary' : ''}>
                  <h2 className="meets-title">
                    <IonIcon
                      className="meets-title-icon"
                      icon={calendarOutline}
                    ></IonIcon>
                    <span>{dm.title}</span>
                  </h2>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              {dm.meets?.map((m) => (
                <IonCol key={m.id} size-xs="12" size-md="6" size-lg="6">
                  <IonCard
                    routerLink={`${privateRoutePaths.meets}/${m.id}`}
                    button
                    routerDirection="forward"
                    className="meet-card"
                  >
                    <IonImg
                      src={m.imageLink || 'assets/images/meet.jpg'}
                      className="meet-card-image"
                    />
                    <IonCardHeader>
                      <IonCardSubtitle>
                        <div className="meets-datetime">
                          {m?.date &&
                            format(new Date(m.date), 'dd.MM.yyyy, HH.mm')}
                        </div>
                      </IonCardSubtitle>
                      <IonCardTitle>{m.name}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      {m.description}
                      <div className="chips">
                        {m.needRegistration && (
                          <IonChip disabled>Регистрация</IonChip>
                        )}
                        {m.needTicket && <IonChip disabled>По билетам</IonChip>}
                        {m.needVote && <IonChip disabled>Голосование</IonChip>}
                      </div>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        ))}
      </div>
    </Layout>
  );
};
