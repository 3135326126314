import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonLabel,
  IonNote,
  IonText,
  useIonModal,
  useIonToast,
  useIonViewDidEnter,
} from '@ionic/react';
import {Layout} from '../../../layout/layout/Layout';
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';
import {Helmet} from 'react-helmet';
import {logoGoogle} from 'ionicons/icons';
import {useAuth} from '../../../auth';
import {useCallback, useState} from 'react';
import {useLocation, useParams} from 'react-router';
import './Login.css';

const ConsentForm: React.FC<{
  hash: string;
  onDismiss: () => void;
  getUser: (gaToken: string, hash: string) => Promise<void>;
}> = ({hash, onDismiss, getUser}) => {
  const [agreement, setAgreement] = useState(false);
  const [present] = useIonToast();

  const onGoogleSignIn = useCallback(async () => {
    const result = await GoogleAuth.signIn();
    if (result?.authentication?.accessToken) {
      try {
        await getUser(result?.authentication?.accessToken, hash);
        onDismiss();
      } catch (e: any) {
        present(e?.message || 'Что-то пошло не так', 3000);
      }
    }
  }, [getUser, hash, present, onDismiss]);

  return (
    <IonContent >
      <div className="login__modal">
        <IonText className="login__modal-block login__modal-header">
          <h1>Гостевой вход</h1>
          <IonButton size="small" onClick={onDismiss} color="secondary">
            Закрыть
          </IonButton>
        </IonText>
        <IonNote className="login__modal-block">
          Перед тем, как войти в систему, - подтверди условия пользования
          сервисом: мы сохраняем твой email как уникальный идентификатор.
          <br />
          <br />
          Приложение находится в тестовом режиме.
          <br />
          <br />
        </IonNote>
        <div className="login__modal-block login__checkbox-wrapper">
          <IonCheckbox
            checked={agreement}
            onIonChange={(e) => setAgreement(!agreement)}
          />
          <IonLabel>
            Я прочитал&nbsp;
            <a target="_blank" href="/privacy">
              политику обработки персональных данных
            </a>
            &nbsp;и даю&nbsp;
            <a target="_blank" href="/agreement">
              согласие на обработку своих данных
            </a>
          </IonLabel>
        </div>
        <IonButton expand="block" disabled={!agreement} onClick={onGoogleSignIn}>
          Вход через Google
        </IonButton>
      </div>
    </IonContent>
  );
};

export const Login: React.FC = () => {
  const {getUser} = useAuth();
  const location = useLocation();
  const {hash} = useParams<{hash?: string}>();
  const handleDismiss = () => {
    dismiss();
  };
  const [present, dismiss] = useIonModal(ConsentForm, {
    hash,
    onDismiss: handleDismiss,
    getUser,
  });

  useIonViewDidEnter(() => {
    GoogleAuth.init();
  }, []);

  const onGoogleSignIn = useCallback(() => {
    present();
  }, [present]);

  const redirectURLFull = `${document.location.protocol}//${
    document.location.hostname.split(':')[0]
  }${location.pathname}`;

  return (
    <Layout name="Вход" doNotShowBack>
      <Helmet>
        <meta
          name="google-signin-client_id"
          content={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}
        />
      </Helmet>
      <div className="login__container">
        <IonText className="login__header">
          <h1>Выбери способ входа</h1>
          <IonNote>
            Вход для сотрудников - через Залогиночную, а для гостей - через
            Google
          </IonNote>
        </IonText>
        <div className="login__buttons-wrapper">
          <IonButton
            size="large"
            href={`https://login.redmadrobot.com/?next=${redirectURLFull}`}
          >
            Я сотрудник
          </IonButton>
          <IonButton size="large" color="secondary" onClick={onGoogleSignIn}>
            <IonIcon icon={logoGoogle} className="login__google-icon" />Я гость
          </IonButton>
        </div>
      </div>
    </Layout>
  );
};
