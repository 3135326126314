import socketIOClient, {Socket} from "socket.io-client";

//TODO: mobile path
class IOSocket {
    socket: Socket | undefined;

    setupSocket(userId: string) {
        if (this.socket) {
            this.socket.disconnect();
        }
        this.socket = socketIOClient(`${window.location.hostname}`, {path: '/api/socket.io', auth: {userId}});
    }
}
export const socket = new IOSocket();
