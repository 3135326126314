import {privateRoutes} from './private';
import {history} from '../services/history';
import {IonRouterOutlet, IonSplitPane} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Menu} from '../modules/layout';
import {Route} from 'react-router';
import {Agreement, NotFound, Privacy} from '../modules/public';
import {publicRoutePaths} from './routePaths';

export const AppRouter = () => {
  return (
    <IonReactRouter history={history}>
      <IonSplitPane contentId="main">
        <Menu />
        <IonRouterOutlet id="main" animated={true}>
          <Route
            component={Privacy}
            key="privacy"
            path={publicRoutePaths.privacy}
          />
          <Route
            component={Agreement}
            key="agreement"
            path={publicRoutePaths.agreement}
          />
          {[...privateRoutes]}
          <Route>
            <NotFound />
          </Route>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  );
};
