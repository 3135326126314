import {Profile} from './auth.entity';
import {api} from '../../services/api';
import {UserSelfUpdate} from '../../../../shared';

const URL = '/api/user';

export const fetchProfile = async (gaToken?: string, inviteToken?: string): Promise<Profile | undefined> => {
  try {
    const profile = await api.get<Profile>(URL, undefined, gaToken ? {
      authorization: `${gaToken}`,
      'x-rmm-invite-token': `${inviteToken}`,
    } : {});
    return profile;
  } catch (e) {
    throw e;
  }
};

export const changeUnit = async (unitId: string): Promise<Profile | undefined> => {
  try {
    const params: UserSelfUpdate = {
      unitId,
    };
    const profile = await api.put<Profile>(URL, params);
    return profile;
  } catch (e) {
    throw new Error(e);
  }
};
