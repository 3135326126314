import React, {useCallback, useEffect, useState} from 'react';
import {useAuth} from '../../../auth';
import {socket} from '../../events.api';
import {VotingEvents, VotingEventPayload} from '../../events.entity';
import {EventsContext, EventsContextValues} from '../EventsContext';

export const EventsProvider: React.FC<{}> = ({children}) => {
  const {isAuth, profile, getUser} = useAuth();
  const [event, setEvent] = useState<
    EventsContextValues['event'] | undefined
  >();

  const setupSocket = useCallback(async () => {
    if (profile?.id) {
      socket.setupSocket(profile.id);
      socket.socket?.on('reload', () => {
        getUser();
      });
      if (profile?.active && profile.confirmed) {
        socket.socket?.onAny(
          (event: VotingEvents, data: VotingEventPayload) => {
            setEvent({
              event,
              data,
            });
          }
        );
      }
    }
  }, [setEvent, profile, getUser]);

  useEffect(() => {
    if (isAuth) {
      setupSocket();
    }
  }, [isAuth, profile, setupSocket]);

  const ctxValues: EventsContextValues = {
    event,
  };
  return (
    <EventsContext.Provider value={ctxValues}>
      {children}
    </EventsContext.Provider>
  );
};
