const HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export const api = {
  get: async <T>(url: string, params?: {[k: string]: string | number}, headers?: HeadersInit): Promise<T> => {
    let queryUrl = url;
    if (params) {
      queryUrl = `${queryUrl}?${Object.keys(params).map(k => `${k}=${params[k]}`).join('&')}`;
    }
    try {
      const response = await fetch(queryUrl, {
        headers: {
          ...HEADERS,
          ...headers,
        },
        method: 'GET',
      });
      if (response.ok) {
        return response.json();
      } else {
        const error = await response.json();
        return Promise.reject(error);
      }
    } catch (e) {
      throw e;
    }
  },
  post: async<T>(url: string, body: any): Promise<T> => {
    try {
      const response = await fetch(url, {
        headers: {
          ...HEADERS,
        },
        method: 'POST',
        body: JSON.stringify(body)
      });

      if (response.ok) {
        return response.json();
      } else {
          return Promise.reject(response);
      }
    } catch (e) {
      throw e;
    }
  },
  put: async<T>(url: string, body: any): Promise<T> => {
    try {
      const response = await fetch(url, {
        headers: {
          ...HEADERS,
        },
        method: 'PUT',
        body: JSON.stringify(body)
      });

      if (response.ok) {
        return response.json();
      } else {
          return Promise.reject(response);
      }
    } catch (e) {
      throw e;
    }
  },
  delete: async<T>(url: string): Promise<T> => {
    const response = await fetch(url, {
      ...HEADERS,
      method: 'DELETE',
    });

    return response.json();
  }
};
