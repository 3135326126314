import {Layout} from '../../../layout/layout/Layout';
import './Privacy.css';

export const Privacy: React.FC = () => {
  return (
    <Layout name="Политика обработки персональных данных">
      <div className="grid-container">
        <h1>Политика обработки персональных данных</h1>
        <br/>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">1. Общие положения</h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              Настоящая политика обработки персональных данных составлена
              в&nbsp;соответствии с&nbsp;требованиями Федерального закона
              от&nbsp;27.07.2006. №&nbsp;152-ФЗ «О&nbsp;персональных данных»
              (далее&nbsp;— Закон о&nbsp;персональных данных) и&nbsp;определяет
              порядок обработки персональных данных и&nbsp;меры
              по&nbsp;обеспечению безопасности персональных данных,
              предпринимаемые Обществом с&nbsp;ограниченной ответственностью
              «РЭДМЭДРОБОТ МСК» (далее&nbsp;— Оператор).
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              1.1. Оператор ставит своей важнейшей целью и&nbsp;условием
              осуществления своей деятельности соблюдение прав и&nbsp;свобод
              человека и&nbsp;гражданина при обработке его персональных данных,
              в&nbsp;том числе защиты прав на&nbsp;неприкосновенность частной
              жизни, личную и&nbsp;семейную тайну.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              1.2. Настоящая политика Оператора в&nbsp;отношении обработки
              персональных данных (далее&nbsp;— Политика) применяется
              ко&nbsp;всей информации, которую Оператор может получить
              о&nbsp;посетителях веб-сайта https://meet.redmadrobot.com/.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              1.3. Политика распространяется на&nbsp;отношения в&nbsp;области
              обработки персональных данных, возникшие у&nbsp;Оператора
              как&nbsp;до, так и&nbsp;после утверждения настоящей Политики.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              2. Основные понятия, используемые в&nbsp;Политике
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.1. Автоматизированная обработка персональных данных&nbsp;—
              обработка персональных данных с&nbsp;помощью средств
              вычислительной техники.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.2. Блокирование персональных данных&nbsp;— временное прекращение
              обработки персональных данных (за&nbsp;исключением случаев, если
              обработка необходима для уточнения персональных данных).
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.3. Веб-сайт&nbsp;— совокупность графических
              и&nbsp;информационных материалов, а&nbsp;также программ для ЭВМ
              и&nbsp;баз данных, обеспечивающих их&nbsp;доступность в&nbsp;сети
              интернет по&nbsp;сетевому адресу https://meet.redmadrobot.com/.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.4. Информационная система персональных данных&nbsp;—
              совокупность содержащихся в&nbsp;базах данных персональных данных,
              и&nbsp;обеспечивающих их&nbsp;обработку информационных технологий
              и&nbsp;технических средств.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.5. Обезличивание персональных данных&nbsp;— действия,
              в&nbsp;результате которых невозможно определить без использования
              дополнительной информации принадлежность персональных данных
              конкретному Пользователю или иному субъекту персональных данных.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.6. Обработка персональных данных&nbsp;— любое действие
              (операция) или совокупность действий (операций), совершаемых
              с&nbsp;использованием средств автоматизации или без использования
              таких средств с&nbsp;персональными данными, включая сбор, запись,
              систематизацию, накопление, хранение, уточнение (обновление,
              изменение), извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.7. Оператор&nbsp;— государственный орган, муниципальный орган,
              юридическое или физическое лицо, самостоятельно или совместно
              с&nbsp;другими лицами организующие&nbsp;и (или) осуществляющие
              обработку персональных данных, а&nbsp;также определяющие цели
              обработки персональных данных, состав персональных данных,
              подлежащих обработке, действия (операции), совершаемые
              с&nbsp;персональными данными.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.8. Персональные данные&nbsp;— любая информация, относящаяся
              прямо или косвенно к&nbsp;определенному или определяемому
              Пользователю веб-сайта https://meet.redmadrobot.com/.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.9. Персональные данные, разрешенные субъектом персональных
              данных для распространения,&nbsp;— персональные данные, доступ
              неограниченного круга лиц к&nbsp;которым предоставлен субъектом
              персональных данных путем дачи согласия на&nbsp;обработку
              персональных данных, разрешенных субъектом персональных данных для
              распространения в&nbsp;порядке, предусмотренном Законом
              о&nbsp;персональных данных (далее&nbsp;— персональные данные,
              разрешенные для распространения).
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.10. Пользователь&nbsp;— любой посетитель веб-сайта
              https://meet.redmadrobot.com/.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.11. Предоставление персональных данных&nbsp;— действия,
              направленные на&nbsp;раскрытие персональных данных определенному
              лицу или определенному кругу лиц.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.12. Распространение персональных данных&nbsp;— любые действия,
              направленные на&nbsp;раскрытие персональных данных неопределенному
              кругу лиц (передача персональных данных) или на&nbsp;ознакомление
              с&nbsp;персональными данными неограниченного круга лиц, в&nbsp;том
              числе обнародование персональных данных в&nbsp;средствах массовой
              информации, размещение в&nbsp;информационно-телекоммуникационных
              сетях или предоставление доступа к&nbsp;персональным данным
              каким-либо иным способом.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.13. Трансграничная передача персональных данных&nbsp;— передача
              персональных данных на&nbsp;территорию иностранного государства
              органу власти иностранного государства, иностранному физическому
              или иностранному юридическому лицу.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              2.14. Уничтожение персональных данных&nbsp;— любые действия,
              в&nbsp;результате которых персональные данные уничтожаются
              безвозвратно с&nbsp;невозможностью дальнейшего восстановления
              содержания персональных данных в&nbsp;информационной системе
              персональных данных&nbsp;и (или) уничтожаются материальные
              носители персональных данных.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              3. Основные права и&nbsp;обязанности Оператора
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">3.1. Оператор имеет право:</p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;получать от&nbsp;субъекта персональных данных достоверные
              информацию и/или документы, содержащие персональные данные;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;в&nbsp;случае отзыва субъектом персональных данных согласия
              на&nbsp;обработку персональных данных Оператор вправе продолжить
              обработку персональных данных без согласия субъекта персональных
              данных при наличии оснований, указанных в&nbsp;Законе
              о&nbsp;персональных данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;самостоятельно определять состав и&nbsp;перечень мер,
              необходимых и&nbsp;достаточных для обеспечения выполнения
              обязанностей, предусмотренных Законом о&nbsp;персональных данных
              и&nbsp;принятыми в&nbsp;соответствии с&nbsp;ним нормативными
              правовыми актами, если иное не&nbsp;предусмотрено Законом
              о&nbsp;персональных данных или другими федеральными законами.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">3.2. Оператор обязан:</p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;предоставлять субъекту персональных данных по&nbsp;его
              просьбе информацию, касающуюся обработки его персональных данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;организовывать обработку персональных данных
              в&nbsp;порядке, установленном действующим законодательством РФ;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;отвечать на&nbsp;обращения и&nbsp;запросы субъектов
              персональных данных и&nbsp;их&nbsp;законных представителей
              в&nbsp;соответствии с&nbsp;требованиями Закона о&nbsp;персональных
              данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;сообщать в&nbsp;уполномоченный орган по&nbsp;защите прав
              субъектов персональных данных по&nbsp;запросу этого органа
              необходимую информацию в&nbsp;течение 30&nbsp;дней с&nbsp;даты
              получения такого запроса;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;публиковать или иным образом обеспечивать неограниченный
              доступ к&nbsp;настоящей Политике в&nbsp;отношении обработки
              персональных данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;принимать правовые, организационные и&nbsp;технические меры
              для защиты персональных данных от&nbsp;неправомерного или
              случайного доступа к&nbsp;ним, уничтожения, изменения,
              блокирования, копирования, предоставления, распространения
              персональных данных, а&nbsp;также от&nbsp;иных неправомерных
              действий в&nbsp;отношении персональных данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;прекратить передачу (распространение, предоставление,
              доступ) персональных данных, прекратить обработку
              и&nbsp;уничтожить персональные данные в&nbsp;порядке
              и&nbsp;случаях, предусмотренных Законом о&nbsp;персональных
              данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;при сборе персональных данных предоставить информацию
              об&nbsp;обработке Персональный данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;в&nbsp;случаях если Персональные данные были получены
              не&nbsp;от&nbsp;субъекта Персональных данных, уведомить
              об&nbsp;этом субъекта;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;при отказе в&nbsp;предоставлении Персональных данных
              субъекту разъясняются последствия такого отказа;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;исполнять иные обязанности, предусмотренные Законом
              о&nbsp;персональных данных.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              4. Основные права и&nbsp;обязанности субъектов персональных данных
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              4.1. Субъекты персональных данных имеют право:
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;получать информацию, касающуюся обработки его персональных
              данных, за&nbsp;исключением случаев, предусмотренных федеральными
              законами. Сведения предоставляются субъекту персональных данных
              Оператором в&nbsp;доступной форме, и&nbsp;в&nbsp;них
              не&nbsp;должны содержаться персональные данные, относящиеся
              к&nbsp;другим субъектам персональных данных, за&nbsp;исключением
              случаев, когда имеются законные основания для раскрытия таких
              персональных данных. Перечень информации и&nbsp;порядок
              ее&nbsp;получения установлен Законом о&nbsp;персональных данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;требовать от&nbsp;оператора уточнения его персональных
              данных, их&nbsp;блокирования или уничтожения в&nbsp;случае, если
              персональные данные являются неполными, устаревшими, неточными,
              незаконно полученными или не&nbsp;являются необходимыми для
              заявленной цели обработки, а&nbsp;также принимать предусмотренные
              законом меры по&nbsp;защите своих прав;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;выдвигать условие предварительного согласия при обработке
              персональных данных в&nbsp;целях продвижения на&nbsp;рынке
              товаров, работ и&nbsp;услуг;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;на&nbsp;отзыв согласия на&nbsp;обработку персональных
              данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;обжаловать в&nbsp;уполномоченный орган по&nbsp;защите прав
              субъектов персональных данных или в&nbsp;судебном порядке
              неправомерные действия или бездействие Оператора при обработке его
              персональных данных;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;на&nbsp;осуществление иных прав, предусмотренных
              законодательством РФ.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              4.2. Субъекты персональных данных обязаны:
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;предоставлять Оператору достоверные данные о&nbsp;себе;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;сообщать Оператору об&nbsp;уточнении (обновлении,
              изменении) своих персональных данных.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              4.3. Лица, передавшие Оператору недостоверные сведения
              о&nbsp;себе, либо сведения о&nbsp;другом субъекте персональных
              данных без согласия последнего, несут ответственность
              в&nbsp;соответствии с&nbsp;законодательством&nbsp;РФ.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              5. Персональные данные, обрабатываемые Оператором
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.1. Оператор может собирать и&nbsp;обрабатывать следующие
              сведения о&nbsp;Пользователе (персональные данные Пользователя):
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">—&nbsp;фамилия, имя, отчество;</p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">—&nbsp;электронный адрес;</p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">—&nbsp;адрес;</p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">—&nbsp;номера телефонов;</p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">—&nbsp;место работы, должность;</p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;корреспонденцию или ее&nbsp;копии, в&nbsp;случае обращения
              Пользователя к&nbsp;Оператору;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;пройденные Пользователем опросы;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;данные об&nbsp;устройстве Пользователя, такие как его
              название и&nbsp;операционная система, тип браузера, язык
              устройства, производитель, информация о&nbsp;сети, информация
              об&nbsp;операторе связи, а&nbsp;также иные доступные технические
              данные;
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              —&nbsp;также на&nbsp;сайте происходит сбор и&nbsp;обработка
              обезличенных данных о&nbsp;посетителях (в&nbsp;т.ч. файлов
              «cookie») с&nbsp;помощью сервисов интернет-статистики (Яндекс
              Метрика и&nbsp;Гугл Аналитика и&nbsp;других) (см. раздел
              «Cookie-файлы» на&nbsp;Сайте).
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.2. Вышеперечисленные данные далее по&nbsp;тексту Политики
              объединены общим понятием Персональные данные.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.3. Обработка специальных категорий персональных данных,
              касающихся расовой, национальной принадлежности, политических
              взглядов, религиозных или философских убеждений, интимной жизни,
              Оператором не&nbsp;осуществляется.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.4. Обработка персональных данных, разрешенных для
              распространения, из&nbsp;числа специальных категорий персональных
              данных, указанных в&nbsp;ч.&nbsp;1&nbsp;ст.&nbsp;10&nbsp;Закона
              о&nbsp;персональных данных, допускается, если соблюдаются запреты
              и&nbsp;условия, предусмотренные&nbsp;ст.&nbsp;10.1 Закона
              о&nbsp;персональных данных.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.5. Согласие Пользователя на&nbsp;обработку персональных данных,
              разрешенных для распространения, оформляется отдельно
              от&nbsp;других согласий на&nbsp;обработку его персональных данных.
              При этом соблюдаются условия, предусмотренные, в&nbsp;частности,
              ст.&nbsp;10.1 Закона о&nbsp;персональных данных. Требования
              к&nbsp;содержанию такого согласия устанавливаются уполномоченным
              органом по&nbsp;защите прав субъектов персональных данных.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.5.1 Согласие на&nbsp;обработку персональных данных, разрешенных
              для распространения, Пользователь предоставляет Оператору
              непосредственно.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.5.2 Оператор обязан в&nbsp;срок не&nbsp;позднее трех рабочих
              дней с&nbsp;момента получения указанного согласия Пользователя
              опубликовать информацию об&nbsp;условиях обработки, о&nbsp;наличии
              запретов и&nbsp;условий на&nbsp;обработку неограниченным кругом
              лиц персональных данных, разрешенных для распространения.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.5.3 Передача (распространение, предоставление, доступ)
              персональных данных, разрешенных субъектом персональных данных для
              распространения, должна быть прекращена в&nbsp;любое время
              по&nbsp;требованию субъекта персональных данных. Данное требование
              должно включать в&nbsp;себя фамилию, имя, отчество (при наличии),
              контактную информацию (номер телефона, адрес электронной почты или
              почтовый адрес) субъекта персональных данных, а&nbsp;также
              перечень персональных данных, обработка которых подлежит
              прекращению. Указанные в&nbsp;данном требовании персональные
              данные могут обрабатываться только Оператором, которому оно
              направлено.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              5.5.4 Согласие на&nbsp;обработку персональных данных, разрешенных
              для распространения, прекращает свое действие с&nbsp;момента
              поступления Оператору требования, указанного в&nbsp;п.&nbsp;5.5.3
              настоящей Политики в&nbsp;отношении обработки персональных данных.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              6. Принципы обработки персональных данных
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              6.1. Обработка персональных данных осуществляется на&nbsp;законной
              и&nbsp;справедливой основе.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              6.2. Обработка персональных данных ограничивается достижением
              конкретных, заранее определенных и&nbsp;законных целей.
              Не&nbsp;допускается обработка персональных данных, несовместимая
              с&nbsp;целями сбора персональных данных.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              6.3. Не&nbsp;допускается объединение баз данных, содержащих
              персональные данные, обработка которых осуществляется
              в&nbsp;целях, несовместимых между собой.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              6.4. Обработке подлежат только персональные данные, которые
              отвечают целям их&nbsp;обработки.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              6.5. Содержание и&nbsp;объем обрабатываемых персональных данных
              соответствуют заявленным целям обработки. Не&nbsp;допускается
              избыточность обрабатываемых персональных данных по&nbsp;отношению
              к&nbsp;заявленным целям их&nbsp;обработки.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              6.6. При обработке персональных данных обеспечивается точность
              персональных данных, их&nbsp;достаточность,
              а&nbsp;в&nbsp;необходимых случаях и&nbsp;актуальность
              по&nbsp;отношению к&nbsp;целям обработки персональных данных.
              Оператор принимает необходимые меры и/или обеспечивает
              их&nbsp;принятие по&nbsp;удалению или уточнению неполных или
              неточных данных.
            </p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              6.7. Хранение персональных данных осуществляется в&nbsp;форме,
              позволяющей определить субъекта персональных данных,
              не&nbsp;дольше, чем этого требуют цели обработки персональных
              данных, если срок хранения персональных данных не&nbsp;установлен
              федеральным законом, договором, стороной которого,
              выгодоприобретателем или поручителем по&nbsp;которому является
              субъект персональных данных. Обрабатываемые персональные данные
              уничтожаются либо обезличиваются в&nbsp;течение 30&nbsp;дней
              по&nbsp;достижении целей обработки или в&nbsp;случае утраты
              необходимости в&nbsp;достижении этих целей, если иное
              не&nbsp;предусмотрено федеральным законом.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              7. Цели обработки персональных данных
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              7.1. Цель обработки персональных данных Пользователя:
            </p>

            <p className="paragraph-1">
              —&nbsp;информирование Пользователя посредством отправки
              электронных писем;
            </p>

            <p className="paragraph-1">
              —&nbsp;сотрудничество с&nbsp;Пользователем как
              с&nbsp;клиентом/представителем медиа;
            </p>

            <p className="paragraph-1">
              —&nbsp;привлечение и&nbsp;отбор кандидатов на&nbsp;работу
              у&nbsp;Оператора;
            </p>

            <p className="paragraph-1">
              —&nbsp;для ответов на&nbsp;запросы Пользователя, направленные
              через Сайт или на&nbsp;корпоративную почту;
            </p>

            <p className="paragraph-1">
              —&nbsp;для организации различных мероприятий (семинаров, воршопов
              и&nbsp;тд);
            </p>

            <p className="paragraph-1">
              —&nbsp;для соблюдения законных обязательств Оператора;
            </p>

            <p className="paragraph-1">
              —&nbsp;чтобы осуществлять, устанавливать или защищать законные
              права Оператора, а&nbsp;также для защиты жизненно важных
              интересов, как Пользователя, так и&nbsp;других лиц;
            </p>

            <p className="paragraph-1">
              —&nbsp;при наличии согласия Пользователя Оператор может также
              обрабатывать данные Пользователей для дополнительных целей.
            </p>

            <p className="paragraph-1">
              7.2. Также Оператор имеет право направлять Пользователю
              уведомления о&nbsp;новых продуктах и&nbsp;услугах, специальных
              предложениях и&nbsp;различных событиях. Пользователь всегда может
              отказаться от&nbsp;получения информационных сообщений, направив
              Оператору письмо на&nbsp;адрес электронной почты
              personaldata@redmadrobot.com с&nbsp;пометкой «Отказ
              от&nbsp;уведомлений о&nbsp;новых продуктах и&nbsp;услугах
              и&nbsp;специальных предложениях».
            </p>

            <p className="paragraph-1">
              7.3. Обезличенные данные Пользователей, собранные с&nbsp;помощью
              сервисов интернет-статистики, служат для сбора информации
              о&nbsp;действиях Пользователей на&nbsp;сайте, улучшения качества
              сайта и&nbsp;его содержания.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              8. Правовые основания обработки персональных данных
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              8.1. Правовыми основаниями обработки персональных данных
              Оператором являются:
            </p>

            <p className="paragraph-1">
              —&nbsp;федеральный закон «О&nbsp;персональных данных»
              от&nbsp;27.07.2006&nbsp;N 152-ФЗ;
            </p>

            <p className="paragraph-1">
              —&nbsp;федеральные законы, иные нормативно-правовые акты
              в&nbsp;сфере защиты персональных данных;
            </p>

            <p className="paragraph-1">
              —&nbsp;согласия Пользователей на&nbsp;обработку
              их&nbsp;персональных данных, на&nbsp;обработку персональных
              данных, разрешенных для распространения.
            </p>

            <p className="paragraph-1">
              8.2. Оператор обрабатывает персональные данные Пользователя только
              в&nbsp;случае их&nbsp;заполнения и/или отправки Пользователем
              самостоятельно через специальные формы, расположенные
              на&nbsp;сайте https://meet.redmadrobot.com/ или направленные Оператору
              посредством электронной почты. Проставляя отметку («галочку»)
              в&nbsp;специальном поле, Пользователь выражает свое согласие
              с&nbsp;данной Политикой.
            </p>

            <p className="paragraph-1">
              8.3. Оператор обрабатывает обезличенные данные
              о&nbsp;Пользователей в&nbsp;случае, если это разрешено
              в&nbsp;настройках браузера Пользователя (включено сохранение
              файлов «cookie» и&nbsp;использование технологии JavaScript).
            </p>

            <p className="paragraph-1">
              8.4. Субъект персональных данных самостоятельно принимает решение
              о&nbsp;предоставлении его персональных данных и&nbsp;дает согласие
              свободно, своей волей и&nbsp;в&nbsp;своем интересе.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              9. Условия обработки персональных данных
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              9.1. Обработка персональных данных осуществляется с&nbsp;согласия
              субъекта персональных данных на&nbsp;обработку его персональных
              данных.
            </p>

            <p className="paragraph-1">
              9.2. Обработка персональных данных необходима для достижения
              целей, предусмотренных законами Российской Федерации или
              международным договором Российской Федерации, для осуществления
              возложенных законодательством Российской Федерации
              на&nbsp;оператора функций, полномочий и&nbsp;обязанностей.
            </p>

            <p className="paragraph-1">
              9.3. Обработка персональных данных необходима для осуществления
              правосудия, исполнения судебного акта, акта другого органа или
              должностного лица, подлежащих исполнению в&nbsp;соответствии
              с&nbsp;законодательством Российской Федерации
              об&nbsp;исполнительном производстве.
            </p>

            <p className="paragraph-1">
              9.4. Обработка персональных данных необходима для исполнения
              договора, стороной которого либо выгодоприобретателем или
              поручителем по&nbsp;которому является субъект персональных данных,
              а&nbsp;также для заключения договора по&nbsp;инициативе субъекта
              персональных данных или договора, по&nbsp;которому субъект
              персональных данных будет являться выгодоприобретателем или
              поручителем.
            </p>

            <p className="paragraph-1">
              9.5. Обработка персональных данных необходима для осуществления
              прав и&nbsp;законных интересов оператора или третьих лиц либо для
              достижения общественно значимых целей при условии, что при этом
              не&nbsp;нарушаются права и&nbsp;свободы субъекта персональных
              данных.
            </p>

            <p className="paragraph-1">
              9.6. Осуществляется обработка персональных данных, доступ
              неограниченного круга лиц к&nbsp;которым предоставлен субъектом
              персональных данных либо по&nbsp;его просьбе (далее&nbsp;—
              общедоступные персональные данные).
            </p>

            <p className="paragraph-1">
              9.7. Осуществляется обработка персональных данных, подлежащих
              опубликованию или обязательному раскрытию в&nbsp;соответствии
              с&nbsp;федеральным законом.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              10. Порядок сбора, хранения, передачи и&nbsp;других видов
              обработки персональных данных
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              Безопасность персональных данных, которые обрабатываются
              Оператором, обеспечивается путем реализации правовых,
              организационных и&nbsp;технических мер, необходимых для выполнения
              в&nbsp;полном объеме требований действующего законодательства
              в&nbsp;области защиты персональных данных.
            </p>

            <p className="paragraph-1">
              10.1. Оператор обеспечивает сохранность персональных данных
              и&nbsp;принимает все возможные меры, исключающие доступ
              к&nbsp;персональным данным неуполномоченных лиц.
            </p>

            <p className="paragraph-1">
              10.2. Персональные данные Пользователя никогда, ни&nbsp;при каких
              условиях не&nbsp;будут переданы третьим лицам, за&nbsp;исключением
              случаев, связанных с&nbsp;исполнением действующего
              законодательства либо в&nbsp;случае, если субъектом персональных
              данных дано согласие Оператору на&nbsp;передачу данных третьему
              лицу для исполнения обязательств по&nbsp;гражданско-правовому
              договору.
            </p>

            <p className="paragraph-1">
              10.3. В&nbsp;случае выявления неточностей в&nbsp;персональных
              данных, Пользователь может актуализировать их&nbsp;самостоятельно,
              путем направления Оператору уведомление на&nbsp;адрес электронной
              почты Оператора personaldata@redmadrobot.com с&nbsp;пометкой
              «Актуализация персональных данных».
            </p>

            <p className="paragraph-1">
              10.4. Срок обработки персональных данных определяется достижением
              целей, для которых были собраны персональные данные, если иной
              срок не&nbsp;предусмотрен договором или действующим
              законодательством. Пользователь может в&nbsp;любой момент отозвать
              свое согласие на&nbsp;обработку персональных данных, направив
              Оператору уведомление посредством электронной почты
              на&nbsp;электронный адрес Оператора personaldata@redmadrobot.com
              с&nbsp;пометкой «Отзыв согласия на&nbsp;обработку персональных
              данных».
            </p>

            <p className="paragraph-1">
              10.5. Вся информация, которая собирается сторонними сервисами,
              в&nbsp;том числе платежными системами, средствами связи
              и&nbsp;другими поставщиками услуг, хранится и&nbsp;обрабатывается
              указанными лицами (Операторами) в&nbsp;соответствии
              с&nbsp;их&nbsp;Пользовательским соглашением и&nbsp;Политикой
              конфиденциальности. Субъект персональных данных и/или Пользователь
              обязан самостоятельно своевременно ознакомиться с&nbsp;указанными
              документами. Оператор не&nbsp;несет ответственность
              за&nbsp;действия третьих лиц, в&nbsp;том числе указанных
              в&nbsp;настоящем пункте поставщиков услуг.
            </p>

            <p className="paragraph-1">
              10.6. Установленные субъектом персональных данных запреты
              на&nbsp;передачу (кроме предоставления доступа), а&nbsp;также
              на&nbsp;обработку или условия обработки (кроме получения доступа)
              персональных данных, разрешенных для распространения,
              не&nbsp;действуют в&nbsp;случаях обработки персональных данных
              в&nbsp;государственных, общественных и&nbsp;иных публичных
              интересах, определенных законодательством РФ.
            </p>

            <p className="paragraph-1">
              10.7. Оператор при обработке персональных данных обеспечивает
              конфиденциальность персональных данных.
            </p>

            <p className="paragraph-1">
              10.8. Оператор осуществляет хранение персональных данных
              в&nbsp;форме, позволяющей определить субъекта персональных данных,
              не&nbsp;дольше, чем этого требуют цели обработки персональных
              данных, если срок хранения персональных данных не&nbsp;установлен
              федеральным законом, договором, стороной которого,
              выгодоприобретателем или поручителем по&nbsp;которому является
              субъект персональных данных.
            </p>

            <p className="paragraph-1">
              10.9. Условием прекращения обработки персональных данных может
              являться достижение целей обработки персональных данных, истечение
              срока действия согласия субъекта персональных данных или отзыв
              согласия субъектом персональных данных, а&nbsp;также выявление
              неправомерной обработки персональных данных.
            </p>

            <p className="paragraph-1">
              10.10. При сборе персональных данных, в&nbsp;том числе посредством
              информационно телекоммуникационной сети интернет, Оператор
              обеспечивает запись, систематизацию, накопление, хранение,
              уточнение (обновление, изменение), извлечение персональных данных
              граждан Российской Федерации с&nbsp;использованием баз данных,
              находящихся на&nbsp;территории Российской Федерации,
              за&nbsp;исключением случаев, указанных в&nbsp;Законе
              о&nbsp;персональных данных.
            </p>

            <p className="paragraph-1">
              10.11. Информация на&nbsp;сайте Оператора (статьи, анонсы
              и&nbsp;тд) может содержать ссылки других сайтов и&nbsp;сервисов,
              а&nbsp;также ссылки, ведущие на&nbsp;другие сайты и&nbsp;сервисы.
              Оператор не&nbsp;несет ответственности за&nbsp;порядок
              использования персональных данных сторонними сайтами, или
              за&nbsp;их&nbsp;содержание. Пользователь сам несет ответственность
              за&nbsp;переход по&nbsp;ссылкам и&nbsp;проверку политики
              использования персональных данных соответствующих веб- сайтов
              и&nbsp;сервисов перед отправкой им&nbsp;любых персональных данных.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              11. Перечень действий, производимых Оператором с&nbsp;полученными
              персональными данными
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              11.1. Оператор осуществляет сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление
              и&nbsp;уничтожение персональных данных.
            </p>

            <p className="paragraph-1">
              11.2. Оператор осуществляет как неавтоматизированную, так
              и&nbsp;автоматизированную обработку персональных данных
              с&nbsp;получением и/или передачей полученной информации
              по&nbsp;информационно-телекоммуникационным сетям или без таковой.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              12. Кому могут быть переданы персональные данные Пользователя
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              Оператор может передавать персональные данные Пользователей:
            </p>

            <p className="paragraph-1">12.1. аффилированным лицам Оператора.</p>

            <p className="paragraph-1">
              12.2. любому компетентному правоохранительному, регулирующему или
              иному государственному органу, суду если такая передача
              персональных данных необходима: (i) в&nbsp;соответствии
              с&nbsp;применимым правом или подзаконным актом, (ii) для
              осуществления, установления или защиты законных прав Оператора,
              или (iii) для защиты жизненно важных интересов, как Пользователя,
              так и&nbsp;любого другого ,лица. Вышеназванные действия
              осуществляются в&nbsp;соответствии с&nbsp;требованиями
              законодательства Российской Федерации.
            </p>

            <p className="paragraph-1">
              12.3. лицам и&nbsp;компаниям вне Оператора, которые предоставляют
              услуги для нас и&nbsp;обрабатывают персональные данные
              от&nbsp;нашего имени при предоставлении этих услуг (например,
              хостинг-провайдеры, службы поддержки, компании, предоставляющие
              услуги атрибуции, службы управления социальными сетями, службы
              безопасности, службы оптимизации, службы рассылки, маркетинговые
              и&nbsp;рекламные агентства, службы аналитики, сервисы,
              увеличивающие функциональность услуг, и&nbsp;иные сервисы,
              помогающие нам развиваться и&nbsp;управлять нашими Услугами).
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              13. Трансграничная передача персональных данных
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              13.1. Оператор до&nbsp;начала осуществления трансграничной
              передачи персональных данных обязан убедиться в&nbsp;том, что
              иностранным государством, на&nbsp;территорию которого
              предполагается осуществлять передачу персональных данных,
              обеспечивается надежная защита прав субъектов персональных данных.
            </p>

            <p className="paragraph-1">
              13.2. Трансграничная передача персональных данных
              на&nbsp;территории иностранных государств, не&nbsp;отвечающих
              вышеуказанным требованиям, может осуществляться только
              в&nbsp;случае наличия согласия в&nbsp;письменной форме субъекта
              персональных данных на&nbsp;трансграничную передачу его
              персональных данных и/или исполнения договора, стороной которого
              является субъект персональных данных.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              14. Конфиденциальность персональных данных
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              Оператор и&nbsp;иные лица, получившие доступ к&nbsp;персональным
              данным, обязаны не&nbsp;раскрывать третьим лицам
              и&nbsp;не&nbsp;распространять персональные данные без согласия
              субъекта персональных данных, если иное не&nbsp;предусмотрено
              федеральным законом.
            </p>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6 h4_padd--privacy">
            <h4 className="heading h4_c">
              15. Заключительные положения. Как связаться с&nbsp;Оператором.
            </h4>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-6">
            <p className="paragraph-1">
              15.1. Пользователь может получить любые разъяснения
              по&nbsp;интересующим вопросам, касающимся обработки его
              персональных данных, обратившись к&nbsp;Оператору с&nbsp;помощью
              электронной почты personaldata@redmadrobot.com;
            </p>

            <p className="paragraph-1">
              —&nbsp;В&nbsp;случае выявления неточностей в&nbsp;персональных
              данных, для актуализации персональных данных необходимо направить
              письмо на&nbsp;адрес personaldata@redmadrobot.com с&nbsp;пометкой
              «Актуализация персональных данных»;
            </p>

            <p className="paragraph-1">
              —&nbsp;для отзыва согласия на&nbsp;обработку персональных данных,
              необходимо направить письмо на&nbsp;адрес электронной почты
              personaldata@redmadrobot.com с&nbsp;пометкой «Отзыв согласия
              на&nbsp;обработку персональных данных»;
            </p>

            <p className="paragraph-1">
              15.2. В&nbsp;данном документе будут отражены любые изменения
              политики обработки персональных данных Оператором. Политика
              действует бессрочно до&nbsp;замены ее&nbsp;новой версией.
            </p>

            <p className="paragraph-1">
              15.3. Во&nbsp;исполнение
              требований&nbsp;ч.&nbsp;2&nbsp;ст.&nbsp;18.1 Закона
              о&nbsp;персональных данных актуальная версия Политики
              в&nbsp;свободном доступе расположена в&nbsp;сети Интернет
              по&nbsp;адресу https://meet.redmadrobot.com/privacy.
            </p>

            <p className="paragraph-1">
              Редакция от&nbsp;15&nbsp;декабря 2021г.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
