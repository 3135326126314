import {Layout} from '../../../layout/layout/Layout';
import './Agreement.css';

export const Agreement: React.FC = () => {
  return (
    <Layout name="Согласие на обработку персональных данных">
      <div className="grid-container">
        <h1>Согласие на обработку персональных данных</h1>
        <div className="grid-row h4_padd">
          <div className="grid-col-6">
            <p className="paragraph-1">
              Настоящим, в&nbsp;соответствии с&nbsp;Федеральным законом
              от&nbsp;27.07.2006 №&nbsp;152-ФЗ «О&nbsp;персональных данных»,
              вы&nbsp;подтверждаете свое согласие на&nbsp;обработку Обществом
              с&nbsp;ограниченной ответственностью «РЭДМЭДРОБОТ МСК» юридический
              адрес: 123557, г. Москва, ул. Пресненский Вал, д.27, стр.9,
              в&nbsp;том числе Группе компаний Redmadrobot (аффилированные лица
              ООО «РЭДМЭДРОБОТ МСК», далее&nbsp;— Redmadrobot) ваших
              (далее&nbsp;— Пользователь) персональных данных.
            </p>

            <p className="paragraph-1">
              Настоящим согласием вы&nbsp;подтверждаете, что проинформированы
              о&nbsp;том, что под обработкой персональных данных понимаются
              действия с&nbsp;персональными данными, определенные
              в&nbsp;Федеральном законе №&nbsp;152-ФЗ от&nbsp;27.07.2006
              «О&nbsp;персональных данных», а&nbsp;именно: сбор, запись,
              систематизация, накопление, хранение, уточнение (обновление,
              изменение), извлечение, использование, передача (предоставление,
              доступ), блокирование, удаление персональных данных, совершаемые
              Redmadrobot с&nbsp;использованием средств автоматизации или без
              использования таких средств.
            </p>

            <p className="paragraph-1">
              Данным согласием вы&nbsp;подтверждаете, что проинформированы
              о&nbsp;том, что обработка предоставляемых вами персональных данных
              может осуществляется для достижения следующих целей:
            </p>

            <p className="paragraph-1">
              —&nbsp;информирование Пользователя посредством отправки
              электронных писем;
            </p>

            <p className="paragraph-1">
              —&nbsp;сотрудничество с&nbsp;Пользователем как
              с&nbsp;клиентом/представителем медиа;
            </p>

            <p className="paragraph-1">
              —&nbsp;привлечение и&nbsp;отбор кандидатов на&nbsp;работу
              в&nbsp;Redmadrobot;
            </p>

            <p className="paragraph-1">
              —&nbsp;для ответов на&nbsp;запросы Пользователя, направленные
              через сайт https://meet.redmadrobot.com/ или на&nbsp;корпоративную
              почту;
            </p>

            <p className="paragraph-1">
              —&nbsp;для организации различных мероприятий (семинаров, воркшопов
              и&nbsp;тд);
            </p>

            <p className="paragraph-1">
              —&nbsp;для соблюдения законных обязательств Redmadrobot;
            </p>

            <p className="paragraph-1">
              —&nbsp;чтобы осуществлять, устанавливать или защищать законные
              права Redmadrobot, а&nbsp;также для защиты жизненно важных
              интересов, как Пользователя, так и&nbsp;других лиц;
            </p>

            <p className="paragraph-1">
              —&nbsp;при наличии согласия Пользователя Redmadrobot может также
              обрабатывать данные Пользователей для дополнительных целей.
            </p>

            <p className="paragraph-1">
              Настоящее согласие распространяется на&nbsp;следующие персональные
              данные:
            </p>

            <p className="paragraph-1">—&nbsp;фамилия, имя отчество;</p>

            <p className="paragraph-1">—&nbsp;электронный адрес;</p>

            <p className="paragraph-1">—&nbsp;адрес;</p>

            <p className="paragraph-1">—&nbsp;номера телефонов;</p>

            <p className="paragraph-1">—&nbsp;место работы, должность;</p>

            <p className="paragraph-1">
              —&nbsp;корреспонденцию или ее&nbsp;копии, в&nbsp;случае обращения
              Пользователя в&nbsp;Redmadrobot;
            </p>

            <p className="paragraph-1">
              —&nbsp;пройденные Пользователем опросы;
            </p>

            <p className="paragraph-1">
              —&nbsp;данные об&nbsp;устройстве Пользователя, такие как его
              название и&nbsp;операционная система, тип браузера, язык
              устройства, производитель, информация о&nbsp;сети, информация
              об&nbsp;операторе связи, а&nbsp;также иные доступные технические
              данные.
            </p>

            <p className="paragraph-1">
              Срок действия Вашего согласия ограничен сроком, требующимся для
              достижения цели обработки персональных данных, если иной срок
              хранения персональных данных не&nbsp;установлен действующим
              законодательством, однако, Вы&nbsp;вправе в&nbsp;любой момент
              отозвать настоящее согласие, путём направления письменного
              уведомления на&nbsp;электронный адрес:
              personaldata@redmadrobot.com с&nbsp;пометкой " Отзыв согласия
              на&nbsp;обработку персональных данных". Удаление ваших
              персональных данных будет произведено Redmadrobot в&nbsp;течении
              30&nbsp;дней с&nbsp;момента получения данного уведомления.
            </p>

            <p className="paragraph-1">
              Redmadrobot гарантирует соблюдение следующих прав субъекта
              персональных данных: право на&nbsp;получение сведений о&nbsp;том,
              какие персональные данные субъекта персональных данных хранятся
              у&nbsp;Redmadrobot; право на&nbsp;удаление, уточнение или
              исправление хранящихся у&nbsp;Redmadrobot персональных данных;
              иные права, установленные действующим законодательством Российской
              Федерации.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
