import {IonIcon, IonNote, IonText} from '@ionic/react';
import {planetOutline} from 'ionicons/icons';
import {Layout} from '../../../layout/layout/Layout';
import './NotFound.css';

export const NotFound: React.FC = () => {
  return (
    <Layout name="404">
      <div className="not-found__container">
        <IonText className="not-found__header">
          <IonIcon icon={planetOutline} size="large" color="primary" />
          <h1>404</h1>
          <IonNote>Страница не найдена</IonNote>
        </IonText>
      </div>
    </Layout>
  );
};
