
import {api} from '../../services/api';
import {AcceptGroupInvitation, UserGroupByHash} from '../../../../shared';

const GROUPS_URL = '/api/groups';

export const fetchGroupByHash = async (hash: string): Promise<UserGroupByHash | undefined> => {
  try {
    const group = await api.get<UserGroupByHash>(`${GROUPS_URL}/by-hash/${hash}`);
    return group;
  } catch (e: any) {
    throw e;
  }
};

export const acceptInvitation = async (hash: string): Promise<UserGroupByHash | undefined> => {
  try {
    const body: AcceptGroupInvitation = {
      hash,
    };
    const group = await api.put<UserGroupByHash>(`${GROUPS_URL}/accept-invitation`, body);
    return group;
  } catch (e: any) {
    throw e;
  }
};
