import { IonApp } from '@ionic/react';
import { AuthProvider } from './modules/auth';
import { EventsProvider } from './modules/events';
import { CookiesProvider } from 'react-cookie';
import {AppRouter} from './routes';
import pjson from '../package.json';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/fonts.css';

console.log('Version: ', pjson?.version);

const App: React.FC = () => {
  return (
    <IonApp>
      <AuthProvider>
        <EventsProvider>
          <CookiesProvider>
            <AppRouter />
          </CookiesProvider>
        </EventsProvider>
      </AuthProvider>
    </IonApp>
  );
};

export default App;
