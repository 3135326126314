import {useContext} from 'react';
import {AuthContext, AuthContextValues} from '../../components/AuthContext';

export const useAuth = (): AuthContextValues => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth must be used within a AuthProvider.');
  }
  return authContext;
};
