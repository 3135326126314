import React, {useCallback, useEffect, useState} from 'react';
import {fetchProfile} from '../../auth.api';
import {Profile} from '../../auth.entity';
import {AuthContext, AuthContextValues} from '../AuthContext';

export const AuthProvider: React.FC<{}> = ({children}) => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Profile | undefined>();

  const getUser = useCallback(
    async (gaToken?: string, inviteToken?: string) => {
      try {
        const profile = await fetchProfile(gaToken, inviteToken);
        if (profile && !(profile as any).error && profile.email) {
          setProfile(profile);
          setLoading(false);
          return profile;
        }
        setLoading(false);
        return undefined;
      } catch (e) {
        setLoading(false);
        throw e;
      };
    },
    [setLoading, setProfile]
  );

  useEffect(() => {
    getUser();
  }, []);

  const ctxValues: AuthContextValues = {
    isAuth: !!profile,
    profile,
    loading,
    getUser,
  };
  return (
    <AuthContext.Provider value={ctxValues}>{children}</AuthContext.Provider>
  );
};
