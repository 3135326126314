import {RefresherEventDetail} from '@ionic/core';
import {IonIcon, IonNote, IonRefresher, IonRefresherContent, IonText} from '@ionic/react';
import {informationCircleOutline} from 'ionicons/icons';
import {useCallback} from 'react';
import {useAuth} from '../../../auth';
import {Layout} from '../../../layout/layout/Layout';
import './WaitingForConfirmation.css';

export const WaitingForConfirmation: React.FC = () => {
  const {getUser} = useAuth();
  const refresh = useCallback(async (event: CustomEvent<RefresherEventDetail>) => {
    await getUser();
    event.detail.complete();
  }, [getUser]);

  return (
    <Layout name="Подтверждение" doNotShowBack>
      <IonRefresher slot="fixed" onIonRefresh={refresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <div className="waiting-for-confirmation__container">
        <IonText className="waiting-for-confirmation__header">
          <IonIcon icon={informationCircleOutline} size="large" color="primary" />
          <h1>Ждем администратора</h1>
          <IonNote>Приложение станет доступно после подтверждения аккаунта</IonNote>
        </IonText>
      </div>
    </Layout>
  );
};
