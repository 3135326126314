import {useCallback, useEffect, useState} from 'react';
import {RawVote, VoteCreate, Voting} from '../../meets.entity';
import {
  createVote,
  fetchVoteByVotingId,
  fetchVotingByMeetId,
} from '../../meets.api';
import {Layout} from '../../../layout/layout/Layout';
import './Voting.css';
import {
  IonNote,
  IonCard,
  IonImg,
  useIonViewWillEnter,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonSpinner,
  useIonToast,
  IonToggle,
  IonIcon,
} from '@ionic/react';
import {privateRoutePaths} from '../../../../routes/routePaths';
import {useHistory, useParams} from 'react-router';
import {useEvents, VotingEvents} from '../../../events';
import {VotingEventPayload} from '../../../../../../shared';
import {Voter} from '../voter/Voter';
import {VoteResults} from '../vote-results/VoteResults';
import {useAuth} from '../../../auth';
import {useCookies} from 'react-cookie';
import {isPlatform} from '@ionic/react';
import {warningOutline} from 'ionicons/icons';

const eventForRefresh: Array<VotingEvents> = [
  'meet:voting:started',
  'meet:voting:canceled',
  'meet:voting:finished',
  'meet:voting:ended',
];

export const VotingView: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const {profile} = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(['rmm_animation_show']);
  const [showAnimation, setShowAnimation] = useState(
    !!cookies.rmm_animation_show
  );
  const [isInit, setInit] = useState(false);
  const {event} = useEvents();
  const [voting, setVoting] = useState<Voting>();
  const [sendingVote, setSendingVote] = useState(false);
  const [isVoted, setVoted] = useState<boolean>();
  const [errorFetch, setErrorFetch] = useState<string>();
  const history = useHistory();
  const meet = voting?.meet;
  const isNotStarted = voting?.state === 'NOT_STARTED';
  const isStarted = voting?.state === 'STARTED';
  const isFinished = voting?.state === 'FINISHED' || voting?.state === 'ENDED';
  const [present] = useIonToast();
  const isMobileWeb = isPlatform('mobileweb');

  const fetch = useCallback(async () => {
    try {
      const voting = await fetchVotingByMeetId(id);
      if (voting) {
        setVoting(voting);
        const vote = await fetchVoteByVotingId(voting.id);
        if (vote && vote.isVoted !== undefined) {
          setVoted(!!vote.isVoted);
        }
      } else {
        history.replace(privateRoutePaths.base);
      }
    } catch (e: any) {
      setErrorFetch(e.message);
    }
  }, [setVoting, setVoted, history, id, setErrorFetch]);

  const compareEventDataAndFetch = useCallback(
    (data: VotingEventPayload) => {
      if (voting?.id === data?.id) {
        fetch();
      }
    },
    [voting, fetch]
  );

  const changeAnimationState = useCallback(() => {
    if (showAnimation) {
      setCookie('rmm_animation_show', 'true', {path: '/'});
    } else {
      removeCookie('rmm_animation_show', {path: '/'});
    }
    setShowAnimation(!showAnimation);
  }, [setShowAnimation, showAnimation, removeCookie, setCookie]);

  useIonViewWillEnter(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (!isInit) {
      setInit(true);
    } else {
      if (event?.event && eventForRefresh.includes(event?.event)) {
        compareEventDataAndFetch(event.data);
      }
    }
  }, [event]);

  const handleVote = useCallback(
    async (rawVote: RawVote) => {
      setSendingVote(true);
      if (voting?.id && profile?.id) {
        const voteCreate: VoteCreate = {
          votingId: voting?.id,
          types: rawVote.map((t) => ({
            typeId: t.type.id,
            places: t.projects.map((p, i) => ({
              projectId: p.id,
              place: i + 1,
            })),
          })),
        };
        try {
          const voteResult = await createVote(voteCreate);
          if (voteResult?.isVoted) {
            setVoted(voteResult.isVoted);
            present('Голос засчитан', 3000);
          }
        } catch (e) {
          present('Что-то пошло не так, попробуй еще раз', 3000);
        }
      }
      setSendingVote(false);
    },
    [setSendingVote, setVoted, voting, profile, present]
  );

  const background = (
    <div className="background">
      <IonImg
        alt=""
        className="background__image"
        src="assets/images/metal.png"
      />
      {!isMobileWeb && showAnimation && (
        <div className="background__video-wrapper">
          <video
            src="assets/videos/white_bg.mp4"
            className="background__video background__video--white"
            autoPlay
            muted
            loop
          />
          <video
            src="assets/videos/black_bg.mp4"
            className="background__video background__video--black"
            autoPlay
            muted
            loop
          />
        </div>
      )}
      <IonNote className="background__logo">
        <div className="robovoice__name">Robovoice 2.0</div>
        {!isMobileWeb && (
          <div className="robovoice__controls" onClick={changeAnimationState}>
            Анимация{' '}
            <IonToggle
              className="robovoice__toggle"
              checked={showAnimation}
            ></IonToggle>
          </div>
        )}
      </IonNote>
    </div>
  );

  const waitingBlock = isNotStarted && meet && (
    <div className="waiting-block">
      <IonCardHeader>
        <IonCardTitle>Голосование еще не началось</IonCardTitle>
        <IonCardSubtitle color="primary">
          Мероприятие: {meet.name}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <p>Ты можешь подождать здесь</p>
      </IonCardContent>
    </div>
  );

  const votingBlock = isStarted && !isVoted && isVoted !== undefined && voting && meet && (
    <Voter voting={voting} onVote={handleVote} sending={sendingVote} />
  );

  const waitingForEverybodyBlock = isStarted && isVoted && meet && (
    <div className="waiting-block" key="waiting">
      <IonCardHeader>
        <IonCardTitle>Твой голос учтён!</IonCardTitle>
        <IonCardSubtitle color="primary">
          Мероприятие: {meet.name}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonSpinner color="primary" />
        <p>Ждем остальных...</p>
      </IonCardContent>
    </div>
  );

  const loadingBlock = (
    <div className="waiting-block" key="loading">
      <IonCardHeader>
        <IonCardTitle>Загружаем информацию</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonSpinner color="primary" />
      </IonCardContent>
    </div>
  );

  const resultsBlock = isFinished && voting && voting.results && meet && (
    <VoteResults voting={voting} />
  );

  const errorBlock = (
    <div className="waiting-block" key="error">
      <IonCardHeader>
        <IonCardTitle>Ой, ошибка</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div key="warning">
          <IonIcon
            slot="start"
            color="primary"
            size="large"
            icon={warningOutline}
          />
        </div>
        <IonNote>
          В системе произошел сбой.
          <br />
          Обратись к администратору.
        </IonNote>
      </IonCardContent>
    </div>
  );

  const isLoading = (isVoted === undefined || voting === undefined || meet === undefined) && !errorFetch;

  return (
    <Layout name="Голосование">
      <div className="voting-container">
        {background}
        {errorFetch && (
          <IonCard className="voting-card-wrapper">
            {errorBlock}
          </IonCard>
        )}
        {isLoading && <IonCard className="voting-card-wrapper">
            {loadingBlock}
          </IonCard>}
        {!errorFetch && !isLoading && voting && (
          <IonCard className="voting-card-wrapper">
            {waitingBlock}
            {votingBlock}
            {waitingForEverybodyBlock}
            {resultsBlock}
          </IonCard>
        )}
      </div>
    </Layout>
  );
};
