import {RefresherEventDetail} from '@ionic/core';
import {IonIcon, IonNote, IonRefresher, IonRefresherContent, IonText} from '@ionic/react';
import {warningOutline} from 'ionicons/icons';
import {useCallback} from 'react';
import {useAuth} from '../../../auth';
import {Layout} from '../../../layout/layout/Layout';
import './Deactivated.css';

export const Deactivated: React.FC = () => {
  const {getUser} = useAuth();
  const refresh = useCallback(async (event: CustomEvent<RefresherEventDetail>) => {
    await getUser();

    event.detail.complete();
  }, [getUser]);

  return (
    <Layout name="Аккаунт деактивирован" doNotShowBack>
      <IonRefresher slot="fixed" onIonRefresh={refresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <div className="deactivated__container">
        <IonText className="deactivated__header">
          <IonIcon icon={warningOutline} size="large" color="primary" />
          <h1>Ваш аккаунт был деактивирован</h1>
          <IonNote>Свяжитесь с администратором для уточнения информации</IonNote>
        </IonText>
      </div>
    </Layout>
  );
};
